import axios from 'axios'
import router from "@/router";
import { ElMessage } from "element-plus";


const request = axios.create({
    // baseURL: 'http://localhost:9090/',
    baseURL: 'https://student.pg-code-go.com/api',
    timeout: 30000    //前后端响应超时时间
})

// request 拦截器
//对请求做一些处理，如 token
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let token = JSON.parse(localStorage.getItem("Authorization") || '{}');
    if(token){
        config.headers['Authorization'] = token;
    }
    return config;
},error => {
    console.error('request error : '+ error)
    return Promise.reject(error)
});

//response 拦截器
//接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;

        //兼容服务端返回的字符串数据
        if(typeof res === 'string'){
            res = res ? JSON.parse(res) : res;
        }
        if(res.code === '401'){
            router.push('/login')
            ElMessage.error(res.msg)
        }
        if(res.code === '500'){
            ElMessage.error(res.msg)
        }
        return res;
    },
    error => {
        ElMessage.error(error)
        console.error('request error : '+ error)
        return Promise.reject(error)
    }
)

export default request

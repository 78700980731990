import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/style.css'
import * as ElIcon from '@element-plus/icons-vue'  //引入图标
import zhCn from 'element-plus/es/locale/lang/zh-cn'   //引入中文化组件


const app = createApp(App)

for (const [key, component] of Object.entries(ElIcon)) {
    app.component(key, component);
}

app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
})
app.mount('#app')

import { createRouter, createWebHistory } from 'vue-router'
import ManagerView from '../views/ManagerView.vue'

const routes = [
  {
    path: '/',
    name: 'manager',
    component: ManagerView,
    redirect:'/home',   //重定向到主页
    children:[
      {path:'home',name:'home',meta:{name:'系统首页'},component:()=>import('../views/manager/HomeView')},
      {path:'user',name:'user',meta:{name:'用户信息'},component:()=>import('../views/manager/InfoView/UserView')},
      {path:'tool',name:'tool',meta:{name:'班费管理'},component:()=>import('../views/manager/ToolView')},
      {path:'data',name:'data',meta:{name:'素拓管理'},component:()=>import('../views/manager/DataView')},
      {path:'dormitory',name:'dormitory',meta:{name:'学生信息'},component:()=>import('../views/manager/DormitoryView')},
      {path:'activity',name:'activity',meta:{name:'活动信息'},component:()=>import('../views/manager/ActivityView')},
      {path:'attendance',name:'attendance',meta:{name:'出勤管理'},component:()=>import('../views/manager/StudentDaily/Attendance')},
      {path:'homework',name:'homework',meta:{name:'作业管理'},component:()=>import('../views/manager/StudentDaily/HomeWork')},
      {path:'student',name:'student',meta:{name:'学生信息'},component:()=>import('../views/manager/InfoView/StudentViw')},
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/RegisterView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div>

    <el-container>

      <!--    侧边栏-->
      <el-aside :width="asideWidth" style=" min-height: 100vh; background-color:#001529;">
        <div style="height: 60px; color: white; display: flex; align-items: center; justify-content: center">
          <img src="@/assets/logo1.jpg" style="width: 40px; height: 40px;">
          <span class="logo-title" v-show="!isCollapse">软区后台</span>
        </div>

        <el-menu :collapse="isCollapse" :collapse-transition="false" style="border: none;" :default-active="$route.path" background-color="#001529" active-text-color="#fff" text-color="rgba(255,255,255,0.65)" router>
          <el-menu-item index="/home">
            <el-icon><HomeFilled /></el-icon>
            <template #title>
              <span>系统首页</span>
            </template>
          </el-menu-item>
					<el-sub-menu :index="item.menuPath" v-for="item in menuBox" :key="item.menuPath">
						<template #title>
							<el-icon><component :is="item.menuIcon"></component></el-icon>
							<span>{{item.menuName}}</span>
						</template>
						<el-menu-item :index="items.menuChildPath" v-for="items in item.menuChild" :key="items.path">{{items.menuChildName}}</el-menu-item>
					</el-sub-menu>
        </el-menu>

      </el-aside>

      <el-container>
        <!--      头部区域-->
        <el-header>
       <!--          左侧-->
          <el-icon style="font-size: 30px"><component :is="collapseIcon" @click="handleCollapse" ></component></el-icon>
            <el-breadcrumb style="margin-left: 20px;">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{path: $route.path}">{{$route.meta.name}}</el-breadcrumb-item>
            </el-breadcrumb>
       <!--          右侧-->
          <div style="flex: 1; width: 0; display: flex; justify-content: flex-end; align-items: center">
            <el-icon style="font-size: 25px; margin-right: 10px;" @click="handleFull"><FullScreen /></el-icon>
            <el-dropdown placement="bottom">
              <div style="display: flex; align-items: center; cursor: default">
                <img src="@/assets/logo1.jpg" style="width: 40px; height: 40px;">
                <span style="margin-left: 10px;">管理员</span>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>个人信息</el-dropdown-item>
                  <el-dropdown-item>修改密码</el-dropdown-item>
                  <el-dropdown-item @click="login_out">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-header>

        <!--      主体区域-->
        <el-main>
          <router-view/>
        </el-main>
      </el-container>

    </el-container>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import request from "@/utils/request"
// import axios from 'axios'


export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data(){
    return {
      isCollapse: false,
      asideWidth: '200px',
      collapseIcon: 'Expand',
			iconName:'Menu',
			token:'',
			menuBox:[]
		}
  },
  mounted() {
    if(!localStorage.getItem("Authorization")){
      this.$router.push('/login')
    }
  },
	created() {
		let key = localStorage.getItem('Authorization');
		if(key){
			this.token = JSON.parse(key)
			request({
				url:'/sysMenu/type',
				methods: 'get',
				params:{token:this.token}
			}).then(res => {
				if(res.code === '200'){
					this.menuBox = res.data
				}
			})
		}
	},
	methods:{
    handleCollapse(){
      this.isCollapse = !this.isCollapse
      this.asideWidth = this.isCollapse ? '64px' : '200px'
      this.collapseIcon = this.isCollapse ? 'Fold' : 'Expand'
    },
    handleFull(){
      document.documentElement.requestFullscreen()
    },
    login_out(){
      localStorage.removeItem("Authorization")  //移除当前的 token
      this.$router.push('/login')
    }
  }
}
</script>

<style>

.el-aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width .3s;
  box-shadow: 2px 0 6px rgba(0,21,41,.35);
}

.el-menu{
  width: 93% !important;
}
.el-menu--inline{
  background-color: #000c17 !important;
}
.el-menu--inline .el-menu-item{
  background-color: #000c17 !important;
  padding-left: 49px !important;
}

.el-menu-item:hover,.el-sub-menu__title:hover{
  color: #fff !important;
}

.el-sub-menu__title:hover i{
  color: #fff !important;
}

.el-menu-item.is-active{
  background-color: #1890ff !important;
  border-radius: 5px;
}

.el-menu-item{
  height: 40px !important;
  line-height: 40px !important;
}

.el-sub-menu__title{
  height: 40px !important;
  line-height: 40px !important;
}

.el-sub-menu__icon-arrow{
  margin-top: 0;
}

 /*去除收缩时的滚动条*/
.el-aside::-webkit-scrollbar {
  display: none;
}

.logo-title{
  margin-left: 7px;
  font-size: 20px;
  transition: all .3s;
}

.el-header{
  display: flex;
  align-items: center;
  box-shadow: 2px 0 6px rgba(0,21,41,.35);
}


</style>
